
  import { defineComponent } from 'vue'
  import useDealerBreakdownReport from "@/hooks/payment/EntitySiPayout/useDealerBreakdownReport";
  import { ColumnProps } from 'ant-design-vue/es/table/interface';
  import TableWrapper from "@/components/TableWrapper.vue";
  import ViewModal from "@/views/Payment/EntitySiPayout/DealerBreakdownReport/viewModal.vue";
  import CommentModal from '@/views/Payment/components/commentModal.vue'
  import { useRoute } from "vue-router";
  import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
  import {
    FileExcelOutlined} from '@ant-design/icons-vue';
  type Key = ColumnProps['key'];
  const tableWidth = window.innerWidth
  const tableHeight = window.innerHeight-482
  export default defineComponent({
    name: 'VehicleNameSet',
    components: {
      TableWrapper,
      ViewModal,
      CommentModal,
      ExclamationCircleOutlined,
      FileExcelOutlined
    },
    props: {},
    setup() {
      const {
        state, payoutRoundList, regionList, statusList, getInitSelect,
        columnsData, tableData, rowSelection, doSearch, doReset, pageChange, sizeChange, pageSize, pageNumber, total,
        downloadClick, releaseClick, withdrawClick, viewBatchDownload,
        modalVisible, modalClose, modalTitle, fileColumns, fileList, fileRowSelection, confirmClick, btnName, loading,
        viewVisible, openView, fileId, dealerDetailApproveId, getTableData,
        commentVisible, commentData, openComment, submissionList, submissionDeadline, disabledSubmissionDeadline,
        releaseConfirmModalVisible, handleCloseReleaseConfirmModal, handleRelease, exportExcel
      } = useDealerBreakdownReport()
      const route = useRoute()
      // 获取Entity下拉列表
      getInitSelect();
      const handlePageChange = (page: number,pageSize: number) =>{
        pageChange(page, pageSize)
      }
      const handleSizeChange = (page: number,pageSize: number) =>{
        sizeChange(page, pageSize)
      }
      return {
        route,
        state,
        payoutRoundList,
        regionList,
        statusList,
        doSearch,
        doReset,
        tableWidth,
        tableHeight,
        columnsData,
        tableData,
        rowSelection,
        pageSize,
        pageNumber,
        total,
        handlePageChange,
        handleSizeChange,
        downloadClick,
        releaseClick,
        withdrawClick,
        viewBatchDownload,
        modalVisible,
        modalClose,
        modalTitle,
        fileColumns,
        fileList,
        fileRowSelection,
        confirmClick,
        btnName,
        loading,
        viewVisible,
        openView,
        fileId,
        dealerDetailApproveId,
        getTableData,
        commentVisible,
        commentData,
        openComment,
        submissionList,
        submissionDeadline,
        disabledSubmissionDeadline,
        releaseConfirmModalVisible,
        handleCloseReleaseConfirmModal,
        handleRelease,
        FileExcelOutlined,
        exportExcel
      }
    }
  })
  